<template>
  <div class="main-content">
    <avue-crud
      :data="tableData"
      :option="option"
      :table-loading="showLoading"
      :page.sync="page"
      @on-load="onLoad"
    >
      <template #header>
        <div>
          <div class="flex flex-wrap align-items marginBottom20">
            <div class="flex align-items marginRight10 marginBottom10">
              <div class="width250">
                <el-input
                  v-model="searchData.name"
                  placeholder="输入姓名/手机号/公司抬头/邮箱"
                  @keyup.enter.native="handleSearch"
                  clearable
                  maxlength="20"
                ></el-input>
              </div>
            </div>

            <div class="flex align-items marginRight10 marginBottom10">
              <!-- <span class="span marginRight10">类型 : </span> -->
              <div class="width200">
                <el-select
                  class="width250"
                  v-model="searchData.saleType"
                  clearable
                  @change="handleSearch"
                  placeholder="类型"
                >
                  <!-- <el-option
                    value=""
                    label="请选择"
                  ></el-option> -->
                  <el-option
                    :value="2"
                    label="个人"
                  ></el-option>
                  <el-option
                    :value="1"
                    label="公司"
                  ></el-option>
                </el-select>
              </div>
            </div>

            <div class="flex align-items marginRight10 marginBottom10">
              <!-- <span class="span marginRight10">状态 : </span> -->
              <div class="width200">
                <el-select
                  class="width250"
                  v-model="searchData.saleState"
                  clearable
                  @change="handleSearch"
                  placeholder="状态"
                >
                  <!-- <el-option
                    value=""
                    label="请选择"
                  ></el-option> -->
                  <el-option
                    :value="1"
                    label="启用"
                  ></el-option>
                  <el-option
                    :value="2"
                    label="禁用"
                  ></el-option>
                </el-select>
              </div>
            </div>

            <div class="flex align-items marginRight10 marginBottom10">
              <!-- <span class="span marginRight10">销售合伙人类型 : </span> -->
              <div class="width200">
                <el-select
                  class="width250"
                  v-model="searchData.partnerTypeId"
                  clearable
                  @change="handleSearch"
                  placeholder="销售合伙人类型"
                >
                  <el-option
                    v-for="item in typeList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>

            <div class="flex align-items marginRight10 marginBottom10">
              <div class="width300">
                <el-date-picker
                  v-model="searchData.partnerTime"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  @change="handleSearch"
                  clearable
                >
                </el-date-picker>
              </div>

            </div>

            <div class="flex align-items marginRight10 marginBottom10">
              <el-button
                size="medium"
                type="primary"
                icon="el-icon-search"
                @click="handleSearch"
                class="marginLeft10 marginBottom10"
              >查询</el-button>
            </div>

            <div class="flex align-items marginRight10 marginBottom10">
              <el-button
                size="medium"
                type="info"
                icon="el-icon-refresh-right"
                @click="handleReset"
                class="marginLeft10 marginBottom10"
              >重置</el-button>
            </div>

            <div class="flex align-items marginRight10 marginBottom10">
              <el-button
                size="medium"
                type="primary"
                icon="el-icon-plus"
                @click="handleCreate"
                class="marginLeft10 marginBottom10"
              >新增</el-button>
            </div>

          </div>
        </div>
      </template>

      <template
        slot="companyTitle"
        slot-scope="{ row }"
      >
        <span v-if="row.saleType==1">
          {{ row.companyTitle }}
        </span>
        <span v-else>
          /
        </span>
      </template>

      <template
        slot="dutyParagraph"
        slot-scope="{ row }"
      >
        <span v-if="row.saleType==1">
          {{ row.dutyParagraph }}
        </span>
        <span v-else>
          /
        </span>
      </template>

      <template
        slot="address"
        slot-scope="{ row }"
      >
        <span v-if="row.saleType==1">
          {{ row.address }}
        </span>
        <span v-else>
          /
        </span>
      </template>

      <template
        slot="partnerUsername"
        slot-scope="{ row }"
      >
        <span v-if="row.saleType==2">
          {{ row.partnerUsername }}
        </span>
        <span v-else>
          /
        </span>
      </template>

      <template
        slot="idCard"
        slot-scope="{ row }"
      >
        <span v-if="row.saleType==2">
          {{ row.idCard }}
        </span>
        <span v-else>
          /
        </span>
      </template>

      <template
        slot="workCertImages"
        slot-scope="{ row }"
      >
        <div>
          <el-button
            v-if="row.saleType==2"
            type="text"
            @click="detailsWorkCertImages(row)"
          >查看</el-button>
          <span v-else>/</span>
        </div>
      </template>

      <template
        slot="introducer"
        slot-scope="{ row }"
      >
        <div>
          <span v-if="row.saleType==2">
            {{ row.introducer }}
          </span>
          <span v-else>/</span>
        </div>
      </template>

      <template
        slot="introducerPhone"
        slot-scope="{ row }"
      >
        <div>
          <span v-if="row.saleType==2">
            {{ row.introducerPhone }}
          </span>
          <span v-else>/</span>
        </div>
      </template>

      <template
        slot="saleState"
        slot-scope="{ row }"
      >
        <el-switch
          v-model="row.saleState"
          :inactive-value="2"
          :active-value="1"
          @change="handleSwitch($event, row.id)"
        ></el-switch>
      </template>

      <template
        slot="menu"
        slot-scope="{ row }"
      >

        <el-button
          type="text"
          size="mini"
          @click="handleEdit(row)"
        >编辑</el-button>
        <el-button
          type="text"
          size="mini"
          @click="handleDelete(row)"
        >删除</el-button>
        <el-button
          type="text"
          size="mini"
          @click="handleRevenue(row)"
        >收益明细</el-button>
        <el-button
          type="text"
          size="mini"
          @click="handleBindingRelationship(row)"
        >绑定关系</el-button>
        <el-button
          type="text"
          size="mini"
          @click="handleAtomicFission(row)"
        >裂变赠送设置</el-button>

      </template>
    </avue-crud>

    <el-dialog
      v-if="dialogVisibleWorkCertImages"
      title="职业证明"
      :visible.sync="dialogVisibleWorkCertImages"
      width="40%"
      :before-close="handleCloseWorkCertImages"
    >
      <el-carousel style="text-align: center;">
        <el-carousel-item
          v-for="item in this.workCertImages"
          :key="item"
        >
          <img
            :src="item"
            alt="职业证明"
            width='auto'
            height='300px'
          >
        </el-carousel-item>
      </el-carousel>
    </el-dialog>
  </div>
</template>

<script>
import {
  deleteNew,
  list,
  updateNew,
  getTypeList,
} from "@/api/region/commission";
import dayjs from "dayjs";
export default {
  name: "index",
  data() {
    return {
      typeList: [],
      searchData: {},
      page: {
        total: 0,
        currentPage: 1,
      },
      tableData: [],
      showLoading: false,
      option: {
        title: "分佣金白名单设置",
        titleSize: "h3",
        card: true,
        border: true,
        header: false,
        stripe: false, // 显示斑马纹
        showHeader: true, // 显示表头
        index: false, // 显示索引
        indexLabel: "序号", // 索引文字
        size: "small",
        selection: false, // 显示多选框
        page: true, // 显示分页
        align: "center",
        menuAlign: "center",
        menuType: "text",
        editBtn: false,
        delBtn: false,
        column: [
          // {
          //   label: "销售合伙人编号",
          //   prop: "salePartnerCode",
          // },
          {
            label: "类型",
            prop: "saleType",
            formatter: function (row, column, cellValue) {
              return row.saleType == 1 ? "公司" : row.saleType == 2 ? "个人" : "";
            },
          },
          {
            label: "公司抬头",
            prop: "companyTitle",
          },
          {
            label: "税号",
            prop: "dutyParagraph",
          },
          {
            label: "联系电话",
            prop: "partnerPhone",
            width:100,
          },
          {
            label: "邮箱",
            prop: "email",
          },
          {
            label: "公司地址",
            prop: "address",
          },
          {
            label: "收款账号",
            prop: "gatherPriceAccount",
          },
          {
            label: "姓名",
            prop: "partnerUsername",
          },
          {
            label: "身份证号",
            prop: "idCard",
          },
          // {
          //   label: "介绍人姓名",
          //   prop: "introducer",
          // },
          // {
          //   label: "介绍人手机号",
          //   prop: "introducerPhone",
          // },
          {
            label: "职业证明",
            prop: "workCertImages",
            formatter: function (row, column, cellValue) {
              const workCertImages = cellValue.split(",");

              return workCertImages;
            },
          },
          {
            label: "销售合伙人类型",
            prop: "partnerTypeName",
          },
          {
            label: "分销比例",
            prop: "retailScale",
            formatter: function (row, column, cellValue) {
              return `${cellValue}%`;
            },
          },
          {
            label: "关联的优惠劵",
            prop: "couponName",
            width:100,
          },
          {
            label: "合作伙伴总数/人",
            prop: "cooperateNum",
            width:120,
          },
          {
            label: "推广总收益/元",
            prop: "popularizeTotalPrice",
            width:100,
          },
          {
            label: "待提现收益/元",
            prop: "preWithdrawPrice",
            width:100,
          },
          {
            label: "待结算收益/元",
            prop: "preSettlePrice",
            width:100,
          },
          {
            label: "状态",
            prop: "saleState",
            formatter: function (row, column, cellValue) {
              return row.saleState == 1 ? "启用" : row.saleState == 2 ? "禁用" : "";
            },
          },
          {
            label: "创建时间",
            prop: "partnerCreateTime",
            width:86,
          },
        ],
      },
      dialogVisibleWorkCertImages: false,
      form: {
        id: "",
        type: "",
        name: "",
      },
      workCertImages: [],
    };
  },
  created() {
    const searchForm = JSON.parse(localStorage.getItem("searchForm"));
    if (searchForm?.path === this.$route.path) {
      this.searchData = searchForm.searchData;
    } else {
      localStorage.removeItem("searchForm");
    }
  },
  mounted() {
    this.getTypeList();
  },
  methods: {
    getTypeList() {
      getTypeList().then((res) => {
        if (res.code === 200) {
          this.typeList = res.data;
        }
      });
    },
    handleCloseWorkCertImages() {
      this.dialogVisibleWorkCertImages = false;
    },
    detailsWorkCertImages(row) {
      this.workCertImages = row?.workCertImages?.split(",");
      this.dialogVisibleWorkCertImages = true;
    },
    onLoad() {
      const searchForm = {
        path: this.$route.path,
        searchData: this.searchData,
      };
      localStorage.setItem("searchForm", JSON.stringify(searchForm));
      this.showLoading = true;
      const searchData = {
        saleState: this.searchData.saleState,
        name: this.searchData.name,
        partnerTypeId: this.searchData.partnerTypeId,
        saleType: this.searchData.saleType,
      };
      if (this.searchData.partnerTime) {
        searchData.partnerTimeStart = dayjs(
          this.searchData.partnerTime[0]
        ).format("YYYY-MM-DD 00:00:00");
        searchData.partnerTimeEnd = dayjs(
          this.searchData.partnerTime[1]
        ).format("YYYY-MM-DD 23:59:59");
      }

      list({
        page: this.page.currentPage,
        size: this.page.pageSize,
        ...searchData,
      }).then((res) => {
        this.showLoading = false;
        if (res.code === 200) {
          this.tableData = res.data.records;
          this.page.total = res.data.total;
        }
      });
    },
    // 查询
    handleSearch() {
      // console.log('查询')

      this.page.currentPage = 1;
      this.onLoad();
    },
    // 重置
    handleReset() {
      // console.log('重置')
      localStorage.removeItem("searchForm");
      this.searchData = {
        saleState: "",
        name: "",
        partnerTypeId: "",
        saleType: "",
        partnerTime: "",
      };
      this.page.currentPage = 1;
      this.onLoad();
    },
    // 添加
    handleCreate() {
      this.$router.push({
        path: "/region/commission/create",
        query: {
          type: "create",
        },
      });
    },
    // 编辑
    async handleEdit(row) {
      this.$router.push({
        path: "/region/commission/create",
        query: {
          type: "edit",
          id: row.id,
        },
      });
    },
    // 收益明细
    handleRevenue(row) {
      this.$router.push({
        path: "/region/commission/revenue",
        query: {
          id: row.id,
        },
      });
    },
    // 好友裂变设置
    handleAtomicFission(row) {
      this.$router.push({
        path: "/region/commission/atomicFission",
        query: {
          id: row.id,
        },
      });
    },
    handleBindingRelationship(row) {
      this.$router.push({
        path: "/region/commission/bindingRelationship",
        query: {
          id: row.id,
        },
      });
    },
    // 删除
    handleDelete(row) {
      // console.log('删除',row)
      this.$confirm("确定删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deleteNew({
          id: row.id,
        }).then((res) => {
          if (res.code === 200) {
            this.$message.success("删除成功!");
            this.onLoad();
          } else {
            this.$message.error(res.msg);
          }
        });
      });
    },
    handleSwitch(e, id) {
      // console.log(e,id)
      updateNew({
        id: id,
        saleState: e,
      }).then((res) => {
        if (res.code === 200) {
          this.$message.success("操作成功");
        } else {
          this.$message.error(res.msg);
        }
        this.onLoad();
      });
    },
    // 重置form
    resetForm() {
      this.searchData = {
        state: "",
        name: "",
        createTime: "",
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.password-box {
  background: #e6f7ff;
  border: 2px solid #02a7f0;
  padding: 20px;
  position: relative;

  .icon {
    color: #faad14;
    position: absolute;
    left: 20px;
    top: 24px;
    font-size: 24px;
  }
  .password-content {
    padding: 0 40px;

    .title {
      font-size: 20px;
      margin-bottom: 20px;
    }
  }

  .password-copy {
    font-size: 16px;
  }
}
/deep/.el-carousel__button {
    display: block;
    opacity: .48;
    width: 10px!important;
    height: 2px;
    background-color: #FFF;
    border: none;
    outline: 0;
    padding: 0;
    margin: 0;
    cursor: pointer;
    transition: .3s;
}
</style>